<template>
  <div>
    <iframe class="box" seamless :src="url"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: ''
    }
  },
  created() {
    this.url = this.$route.query.link
  }
}
</script>

<style lang="less" scoped>
.box {
  border: none;
  width: 100vw;
  height: 100vh;
}
</style>
